<template>
  <div class="route-making" ref="routeMaking">
    <div class="form-area">
      <el-form
        :inline="true"
        :rules="rules"
        ref="form"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="92px"
      >
        <el-form-item label="线路名称：" prop="companyId">
          <el-select
            v-model="form.companyId"
            clearable
            filterable
            @change="changeBusLine"
            placeholder="请选择"
          >
            <el-option
              v-for="item in busLineList"
              :key="item.companyId"
              :label="item.companyName"
              :value="item.companyId"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="上下行：" prop="moveType">
          <el-select v-model="form.moveType" placeholder="请选择" clearable>
            <el-option label="上行" :value="0"></el-option>
            <el-option label="下行" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="0">
          <el-button
            type="primary"
            size="small"
            @click="onSearchGaude"
            :loading="searchLoading"
            >获取高德线路</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="onSearchLocal"
            :loading="searchLoading"
            >查询本地线路</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 地图 -->
    <div id="container" class="monitoringMap"></div>
    <!-- 地图 -->
    <div class="parma-section">
      <div class="sub-container">
        <div class="opt">
          <p class="opt-title">颜色</p>
          <el-color-picker v-model="color" size="small"></el-color-picker>
        </div>
        <div class="opt">
          <p class="opt-title">线宽</p>
          <el-input-number
            v-model="strokeWeight"
            controls-position="right"
            :max="20"
            :min="1"
            size="small"
          ></el-input-number>
        </div>
        <div class="opt">
          <p class="opt-title">透明度</p>
          <el-input-number
            v-model="strokeOpacity"
            controls-position="right"
            :precision="1"
            :step="0.1"
            :max="1"
            :min="0.1"
            size="small"
          ></el-input-number>
        </div>
        <div class="opt opt1">
          <el-button type="primary" size="small" @click="drawPolyline"
            >自定义线路</el-button
          >
        </div>
        <div class="opt opt1">
          <el-button
            type="primary"
            size="small"
            :disabled="isDrawLine || pathArr.length == 0"
            @click="editLine"
            >{{ isEdit ? "完成" : "编辑线路" }}</el-button
          >
        </div>
        <div class="opt opt1">
          <el-button
            type="primary"
            size="small"
            @click="onSave"
            :disabled="pathArr.length == 0"
            >保存线路信息</el-button
          >
        </div>
        <div class="opt opt1">
          <el-button type="info" size="small" @click="clearAll"
            >清空所有数据</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryAllBusLineList,
  saveLineCoordinates,
  queryLineCoordinates
} from '@/api/lib/bus-api.js'
export default {
  name: 'routeMaking',
  data () {
    return {
      map: null,
      linesearch: null,
      heatmapData: [],
      form: {
        companyId: null,
        companyName: null,
        moveType: "",
      },
      rules: {
        companyId: [{ required: true, message: "请选择线路", trigger: "blur" }],
        moveType: [{ required: true, message: "请选择上下行", trigger: "blur" }],
      },
      busLineList: [],
      color: '#5298ff',
      strokeWeight: 6,
      strokeOpacity: 0.8,
      city: null,
      searchLoading: false,
      readyAdd: [],
      pathArr: [],
      isEdit: false,
      isDrawLine: false,
      mouseTool: null
    }
  },
  methods: {
    initMap () {
      let self = this
      let map = new AMap.Map('container', {
        mapStyle: 'amap://styles/fresh',
        resizeEnable: true,
        expandZoomRange: true,
        features: ['bg', 'road', 'building', 'point'],
        zoom: 12
      })
      this.map = map
      this.map.on('complete', function () {
        self.setLineSearch()
      });
    },
    //选择线路
    changeBusLine (value) {
      if (value) {
        let line = this.busLineList.find(item => item.companyId == value)
        if (line) {
          this.form.companyName = line.companyName
          this.city = line.city[1]
        }
      }
      else {
        this.form.companyName = null
        this.city = null
      }
    },
    setLineSearch () {
      this.linesearch = new AMap.LineSearch({
        pageIndex: 1,
        city: '广元',
        pageSize: 2,
        extensions: 'all'
      });
      this.mouseTool = new AMap.MouseTool(this.map)
    },
    //查询高德公交路线
    onSearchGaude () {
      let self = this
      this.$refs.form.validate(valid => {
        if (valid) {
          this.linesearch.setCity(this.city)
          this.linesearch.search(this.form.companyName, function (status, result) {
            if (status === 'complete' && result.info === 'OK') {
              self.lineSearch_Callback(result);
            } else {
              self.$message.warning("暂无线路数据")
              self.clearMap()
            }
          })
        }
      })

    },
    //查询本地公交路线
    onSearchLocal () {
      this.$refs.form.validate(valid => {
        if (valid) {
          queryLineCoordinates({ companyId: this.form.companyId }).then(res => {
            if (res.code === 1000 && res.data.length > 0) {
              let direction = this.form.moveType === 0 ? 1 : 2
              let data = res.data.find(item => item.moveType === direction)
              if (data) {
                let line = JSON.parse(data.coordinates)
                this.color = data.color
                this.strokeWeight = data.width
                this.strokeOpacity = data.pellucidity
                this.lineSearchLocal_Callback(line, data.companyName);
              } else {
                this.$message.warning("暂无线路数据")
                this.clearMap()
              }
            } else {
              this.$message.warning("暂无线路数据")
              this.clearMap()
            }
          })
        }
      })
    },
    //绘制本地公交路线
    lineSearchLocal_Callback (pathArr, companyName) {
      let self = this
      let path = []
      this.clearMap()
      let lineName = self.delBrace(companyName)
      if (pathArr.length === 0) {
        this.$message.warning("暂无线路数据")
        return
      }
      pathArr.map(item => {
        path.push([item.lng, item.lat])
      })
      let polyline = new AMap.Polyline({
        path: path,
        strokeColor: self.color || "#5298ff",
        strokeOpacity: self.strokeOpacity || 0.8,
        strokeWeight: self.strokeWeight || 6,
        strokeDasharray: [25, 10],
        lineJoin: "round",
        lineCap: "round",
        cursor: "default",
        extData: lineName
      });
      polyline.setMap(self.map)
      self.readyAdd.push(lineName)
      self.pathArr = path
      // 缩放地图到合适的视野级别
      self.map.setFitView([polyline])
    },
    //绘制高德公交路线
    lineSearch_Callback (data) {
      let self = this
      this.clearMap()
      let direction = self.form.moveType
      let pathArr = data.lineInfo[direction].path
      let lineName = self.delBrace(data.lineInfo[direction].name)
      if (!pathArr || pathArr.length === 0) {
        this.$message.warning("暂无线路数据")
        return
      }
      let polyline = new AMap.Polyline({
        path: pathArr,
        strokeColor: self.color || "#5298ff",
        strokeOpacity: self.strokeOpacity || 0.8,
        strokeWeight: self.strokeWeight || 6,
        strokeDasharray: [25, 10],
        lineJoin: "round",
        lineCap: "round",
        cursor: "default",
        extData: lineName
      });
      polyline.setMap(self.map)
      self.readyAdd.push(lineName)
      self.pathArr = pathArr
      // 缩放地图到合适的视野级别
      self.map.setFitView([polyline])
    },
    //编辑线路||完成
    editLine () {
      if (!this.isEdit) {
        let lineName = this.form.companyName
        this.openPolyEditor(lineName)
      } else {
        this.polyEditor.close()
      }
      this.isEdit = !this.isEdit
    },
    //编辑线路
    openPolyEditor (lineName) {
      let self = this
      for (var marks = self.map.getAllOverlays(), i = 0; i < marks.length; i++) {
        if ("AMap.Polyline" == marks[i].CLASS_NAME) {
          // if (marks[i].getExtData() !== lineName) {
          //   self.map.remove(marks[i])
          //   var index = self.readyAdd.indexOf(marks[i].getExtData());
          //   self.readyAdd.splice(index, 1)
          // } else {
            let polyEditor = new AMap.PolyEditor(self.map, marks[i])
            polyEditor.open()
            polyEditor.on('end', function (event) {
              // self.getPath(marks[i].getPath());
              self.pathArr = event.target.getPath()
              // event.target 即为编辑后的折线对象
            })
            this.polyEditor = polyEditor
          // }
        }
      }
    },
    //自定义绘制路线
    drawPolyline () {
      let self = this
      this.isDrawLine = true
      this.clearMap()
      this.mouseTool.polyline({
        strokeColor: self.color || "#5298ff",
        strokeOpacity: self.strokeOpacity || 0.8,
        strokeWeight: self.strokeWeight || 6,
        strokeStyle: "solid",
      })
      this.mouseTool.on('draw', function (e) {
        // event.obj 为绘制出来的覆盖物对象 e.obj.getPath() self.mouseTool.close();
        self.pathArr = e.obj.getPath()
        self.mouseTool.close();
      })
    },
    clearMap () {
      this.map.clearMap()
      this.readyAdd.length = 0
      this.pathArr = []

    },
    clearAll () {
      this.map.clearMap()
      this.readyAdd.length = 0
      this.mouseTool.close();
      this.isDrawLine = false
      this.isEdit = false
    },
    //保存
    onSave () {
      if (this.pathArr.length == 0) {
        this.$message.warning("请先绘制路线")
        return
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          let data = {
            companyId: this.form.companyId,
            color: this.color,
            pellucidity: this.strokeOpacity,
            width: this.strokeWeight,
          }
          if (this.form.moveType == 0) {
            data.moveType = 1
            data.coordinates = JSON.stringify(this.pathArr)
          } else {
            data.moveType = 2
            data.coordinates = JSON.stringify(this.pathArr)
          }
          saveLineCoordinates(data).then(res => {
            if (res.code === 1000) {
              this.$message.success("保存成功")
              this.onSearchLocal()
            }
          })
        }
      })
      this.isDrawLine = false
      this.isEdit = false

    },
    //去除括号
    delBrace (str) {
      str = str.toString()
      return 0 < str.indexOf("(") ? str.substring(0, str.indexOf("(")) : str
    },
    //获取所有线路
    queryAllBusLine () {
      queryAllBusLineList().then((res) => {
        if (res.code === 1000) {
          if (res.data.length == 0) {
            this.$message.warning("还没有线路信息，请先添加线路信息");
          } else {
            this.busLineList = res.data
          }
        }
      })
    },
  },
  created () {
    this.queryAllBusLine()

  },
  mounted () {
    this.initMap()
  },
}
</script>

<style lang="scss" scoped>
.route-making {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  .monitoringMap {
    width: 100%;
    height: calc(100% - 7vh);
  }

  @include themify() {
    .parma-section {
      position: absolute;
      top: 8vh;
      left: 2vh;
      padding: 1.5vh;
      .opt {
        display: flex;
        padding-top: 0.5vh;
        .opt-title {
          width: 80px;
          height: 32px;
          line-height: 32px;
          font-size: 14px;
          text-align: center;
          background: themed("b4");
          color: #fff;
          border-radius: 5px 0 0 5px;
        }
      }
      .opt1 {
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 1vh;
        .el-button {
          width: 18vh;
        }
      }
    }
  }
}
.theme-project-bus {
  .route-making {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
